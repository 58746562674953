import React from "react"
import PropTypes from "prop-types"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import { FormattedMessage } from "react-intl"
import Feedback from "../Feedback"
import FAQ from "../FAQ"
import MyPlaning from "../MyPlaning"
import { NEW, INITIALIZED, useHousehold } from "../../reducers/householdReducer"

const Dashboard = ({ household, onChange }) => {
  const { updateHouseholdMembers } = useHousehold()
  const handleMembersChange = value => {
    onChange(updateHouseholdMembers(value))
  }

  return (
    <Tabs>
      <TabList>
        <Tab>
          <FormattedMessage id="dashboard.tab.one.title" />
        </Tab>
        <Tab>
          <FormattedMessage id="dashboard.tab.two.title" />
        </Tab>
        <Tab>
          <FormattedMessage id="dashboard.tab.three.title" />
        </Tab>
      </TabList>

      <TabPanel>
        <Feedback />
      </TabPanel>
      <TabPanel>
        <MyPlaning
          members={household.members}
          showModifyMember
          displaySave
          onChange={handleMembersChange}
        />
      </TabPanel>
      <TabPanel>
        <FAQ />
      </TabPanel>
    </Tabs>
  )
}

Dashboard.propTypes = {
  household: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.oneOf([NEW, INITIALIZED]),
    boilerSize: PropTypes.oneOf(["SMALL", "MEDIUM", "BIG"]),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        washingHabits: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            day: PropTypes.string.isRequired,
            devices: PropTypes.arrayOf(
              PropTypes.shape({
                type: PropTypes.string.isRequired,
                timeSlices: PropTypes.arrayOf(
                  PropTypes.shape({
                    start: PropTypes.shape({
                      hour: PropTypes.number.isRequired,
                      minute: PropTypes.number.isRequired,
                    }),
                    end: PropTypes.shape({
                      hour: PropTypes.number.isRequired,
                      minute: PropTypes.number.isRequired,
                    }),
                  })
                ),
              })
            ).isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Dashboard
