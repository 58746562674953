import React, { useState } from "react"
import PropTypes from "prop-types"
import CollapseButton from "../CollapseButton"
import FadeIn from "react-fade-in"

const FAQItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = event => {
    setIsOpen(event.target.value)
  }
  return (
    <div className="form-container -faq">
      <div className="faq-head">
        <div className="faq-question">{title}</div>
        <CollapseButton onClick={handleClick} />
      </div>
      {isOpen && (
        <FadeIn>
          <div className="faq-answer">{children}</div>
        </FadeIn>
      )}
    </div>
  )
}

FAQItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default FAQItem
