import React, { useState } from "react"
import PropTypes from "prop-types"

import cn from "classnames"

const CollapseButton = ({ onClick, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
    onClick({ target: { value: !isOpen } })
  }
  return (
    <button className="link -primary -post" onClick={handleClick}>
      <span
        className={cn(
          "link__icon icon-chevron-right",
          { "-open": isOpen },
          { "-close": !isOpen }
        )}
      ></span>
      {children && <span className="link__label">{children}</span>}
    </button>
  )
}

CollapseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default CollapseButton
