import React from "react"
import cn from "classnames"
import PropTypes from "prop-types"

const ACTIVE = "-active"
const ICON_ONLY = "-icon-only"
const SLIM = "-slim"
const Card = ({ icon, label, isActive, onClick, value, slim }) => {
  const handleClick = () => {
    onClick({ target: { value } })
  }
  return (
    <div
      role="button"
      tabIndex="0"
      className={`form-card ${cn(
        { [ACTIVE]: isActive },
        { [ICON_ONLY]: icon && !label },
        { [SLIM]: slim }
      )}`}
      onKeyDown={handleClick}
      onClick={handleClick}
    >
      {icon && (
        <div className="form-card__icon">
          {typeof icon === "string" ? <span className={icon}></span> : icon}
        </div>
      )}
      {label && (
        <div className={`form-card__label ${cn({ [ACTIVE]: isActive })}`}>
          {label}
        </div>
      )}
    </div>
  )
}

Card.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  slim: PropTypes.bool,
}

export default Card
