import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { getLocalizedPath } from "../../i18n"
import { useUser } from "../../reducers/userReducer"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { userItem } = useUser()
  if (
    !userItem &&
    location.pathname !== getLocalizedPath("/app/login", rest.locale)
  ) {
    navigate(getLocalizedPath("/app/login", rest.locale))
    return null
  }
  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}
export default PrivateRoute
