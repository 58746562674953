import React from "react"
import PropTypes from "prop-types"
import PlaningItem from "./PlaningItem"

const Planing = ({ target, devices, onChange }) => {
  const updatedDevice = value =>
    devices.map(device => {
      if (device.type === value.type) {
        return value
      }
      return device
    })

  const handleChange = planingItem => {
    onChange({
      target,
      devices: updatedDevice(planingItem),
    })
  }
  return (
    <div className="row pb-l">
      <div className="col-12 col-md-8 offset-md-2">
        <div className="f-bold py-xs text-left pl-l">{target}</div>
        <div className="form-container">
          <div className="schedule-list">
            {devices.map((device, index) => (
              <PlaningItem
                key={index}
                type={device.type}
                availableTimeSlices={device.availableTimeSlices}
                timeSlices={device.timeSlices}
                onChange={handleChange}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

Planing.propTypes = {
  target: PropTypes.string,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      availableTimeSlices: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.shape({
            hour: PropTypes.number.isRequired,
            minute: PropTypes.number.isRequired,
          }),
          end: PropTypes.shape({
            hour: PropTypes.number.isRequired,
            minute: PropTypes.number.isRequired,
          }),
        })
      ),
      timeSlices: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.shape({
            hour: PropTypes.number.isRequired,
            minute: PropTypes.number.isRequired,
          }),
          end: PropTypes.shape({
            hour: PropTypes.number.isRequired,
            minute: PropTypes.number.isRequired,
          }),
        })
      ),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Planing
