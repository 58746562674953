import React from "react"
import FAQItem from "./FAQItem"
import Body from "./Body"

const FAQ = () => {
  return (
    <Body title="Foire au questions">
      <FAQItem title="Comment sont calculés mes gains monétaires et énergétiques ?">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <br/>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </p>
      </FAQItem>
    </Body>
  )
}

FAQ.propTypes = {}

export default FAQ
