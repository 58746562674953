import React from "react"
import PropTypes from "prop-types"
import { Body } from "../Dashboard"
import { injectIntl, intlShape } from "react-intl"
import Planing from "../Planning"

const MemberPlaning = ({ intl, members, onChange }) => {

  const updatedDevices = (currentDevices, devices) =>
    devices.map((device, index) => {
      if (device.type === currentDevices[index].type) {
        return {
          ...currentDevices[index],
          timeSlices: device.timeSlices,
        }
      } else {
        return device
      }
    })

  const updatedWashingHabits = (planingItem, member) =>
    member.washingHabits.map(washingHabit => {
      if (member.name === planingItem.target) {
        const { target, ...rest } = planingItem
        return {
          ...washingHabit,
          devices: updatedDevices(washingHabit.devices, rest.devices),
        }
      }
      return washingHabit
    })

  const handleChange = (planing, member) => {
    onChange({
      ...member,
      washingHabits: updatedWashingHabits(planing, member),
    })
  }

  return (
    <Body
      title={intl.formatMessage({ id: "member.planing.title" })}
      subTitle={intl.formatMessage({ id: "member.planing.subtitle" })}
    >
      {members.map((member, index) => (
        <Planing
          key={member.id}
          target={member.name}
          devices={member.washingHabits[0].devices}
          onChange={planing => {
            handleChange(planing, member)
          }}
        />
      ))}
    </Body>
  )
}

MemberPlaning.propTypes = {
  intl: intlShape.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      washingHabits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          day: PropTypes.string.isRequired,
          devices: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string.isRequired,
              timeSlices: PropTypes.arrayOf(
                PropTypes.shape({
                  start: PropTypes.shape({
                    hour: PropTypes.number.isRequired,
                    minute: PropTypes.number.isRequired,
                  }),
                  end: PropTypes.shape({
                    hour: PropTypes.number.isRequired,
                    minute: PropTypes.number.isRequired,
                  }),
                })
              ),
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default injectIntl(MemberPlaning)
