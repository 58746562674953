import React from "react"
import PropTypes from "prop-types"

const Card = ({ size, value, label, offset }) => {
  return (
    <div
      className={` pb-l col-12 
        ${
          size === "large" ? "col-md-10 col-lg-8 col-xl-6" : "col-md-5 col-lg-4 col-xl-3"
        } 
        ${
          offset ? "offset-md-1 offset-lg-2 offset-xl-3" : "no-offset"
        }
      `}
    >
      <div className="form-container -dashboard">
        <div className="f-xxl c-brand03 f-bold">{value}</div>
        <div className="f-m c-grey06 f-bold pt-xs">{label}</div>
      </div>
    </div>
  )
}

Card.propTypes = {
  size: PropTypes.oneOf(["small", "large"]),
  value: PropTypes.string,
  label: PropTypes.string,
}

Card.defaultProps = {
  size: "small",
  offset: true
}

export default Card
