import React, { useState } from "react"
import PropTypes from "prop-types"

import StepWizard from "react-step-wizard"
import Member from "../Member"
import MyPlaning from "../MyPlaning"
import { Next } from "../WizardSurvey"
import Previous from "./Previous"
import MemberPlaning from "../MemberPlaning"
import { navigate } from "gatsby"
import { getLocalizedPath } from "../../i18n"
import { useHousehold, NEW, INITIALIZED } from "../../reducers/householdReducer"
import SizeOfBoiler from "../SizeOfBoiler/SizeOfBoiler"
import { injectIntl, intlShape } from "react-intl"
import { useUser } from "../../reducers/userReducer"
import { toast } from "react-toastify"

const WizardSurvey = ({ intl, locale, household, onChange }) => {
  const [StepWizardInstance, setStepWizardInstance] = useState()
  const { userItem } = useUser()
  const {
    householdItem,
    createHousehold,
    updateHouseholdMembers,
  } = useHousehold()
  const [wizard, setWizard] = useState({ hasPreviousButton: false })

  const isLastStep = event =>
    event.state.activeStep === event.props.children.length - 1

  const handleStepChange = event => {
    setWizard({ hasPreviousButton: event.activeStep > 1 })
  }

  const handleMembersChange = value => {
    onChange(updateHouseholdMembers(value))
  }

  const handleSizeOfBoilerChange = event => {
    onChange({ ...householdItem, boilerSize: event.target.value })
  }

  const handleClickNext = event => {
    let nextStep = { index: -1 }
    if (isLastStep(StepWizardInstance)) {
      createHousehold(householdItem)
        .then(() => {
          toast.success(
            intl.formatMessage({ id: "my.planing.save.action.success" })
          )
          navigate(getLocalizedPath("/app/home/", locale))
        })
        .catch(() => {
          toast.success(
            intl.formatMessage({ id: "my.planing.save.action.error" })
          )
        })
    }
    return { ...nextStep, hasError: false }
  }

  return (
    <>
      {wizard.hasPreviousButton && (
        <Previous StepWizardInstance={StepWizardInstance} />
      )}
      <StepWizard
        onStepChange={handleStepChange}
        instance={setStepWizardInstance}
      >
        <SizeOfBoiler
          value={household.boilerSize}
          onChange={handleSizeOfBoilerChange}
        />
        <Member
          title={intl.formatMessage(
            {
              id: "member.survey.title",
            },
            { username: userItem.sensaUser.lastName }
          )}
          subtitle={intl.formatMessage({
            id: "member.survey.subtitle",
          })}
          members={household.members}
          onChange={handleMembersChange}
        />
        <MemberPlaning
          members={household.members}
          onChange={handleMembersChange}
        />
        <MyPlaning members={household.members} onChange={handleMembersChange} />
      </StepWizard>
      <Next StepWizardInstance={StepWizardInstance} onClick={handleClickNext} />
    </>
  )
}

WizardSurvey.propTypes = {
  intl: intlShape.isRequired,
  locale: PropTypes.string.isRequired,
  household: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.oneOf([NEW, INITIALIZED]),
    boilerSize: PropTypes.oneOf(["SMALL", "MEDIUM", "BIG"]),
    members: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        washingHabits: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            day: PropTypes.string.isRequired,
            devices: PropTypes.arrayOf(
              PropTypes.shape({
                type: PropTypes.string.isRequired,
                timeSlices: PropTypes.arrayOf(
                  PropTypes.shape({
                    start: PropTypes.shape({
                      hour: PropTypes.number.isRequired,
                      minute: PropTypes.number.isRequired,
                    }),
                    end: PropTypes.shape({
                      hour: PropTypes.number.isRequired,
                      minute: PropTypes.number.isRequired,
                    }),
                  })
                ),
              })
            ).isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default injectIntl(WizardSurvey)
