import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

const Loader = loading => {
  return (
    <div className="loader__backdrop">
      <div className={cn("loader__indicator", { rotate: loading })}></div>
    </div>
  )
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default Loader
