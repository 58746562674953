import React from "react"
import PropTypes from "prop-types"

const Body = ({ title, subTitle, children }) => {
  return (
    <div className="container py-l">
      <div className="row pb-xxl py-l text-center">
        <div className="col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
          <h1>{title}</h1>
          {subTitle && <p className="pt-l">{subTitle}</p>}
        </div>
      </div>
      {children}
    </div>
  )
}

Body.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Body
