import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Body from "../Dashboard/Body"
import { Input } from "../Form"
import { useHousehold, addWeekDays } from "../../reducers/householdReducer"
import cn from "classnames"
import { FormattedMessage, injectIntl, intlShape } from "react-intl"
import { useUser } from "../../reducers/userReducer"
import { navigate } from "gatsby"
import { getLocalizedPath } from "../../i18n"
import { firebaseAppAuth } from "../../firebase"
import { toast } from "react-toastify"

const Member = ({ title, subtitle, intl, locale, min, max, displaySave }) => {
  const {
    householdItem,
    fetchHousehold,
    updateHousehold,
    saveHousehold,
  } = useHousehold()

  const { userItem } = useUser()

  useEffect(() => {
    if (firebaseAppAuth.currentUser) {
      fetchHousehold(userItem.sensaUser.uuid)
    }
  }, [fetchHousehold, userItem.sensaUser])

  const handleClickSave = () => {
    saveHousehold(householdItem)
      .then(() => {
        toast.success(intl.formatMessage({ id: "member.save.action.success" }))
        navigate(getLocalizedPath("/app/home/", locale))
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: "member.save.action.error" }))
      })
  }

  const handleNameChange = (event, id) => {
    updateHousehold(
      updatedHouseHoldMembers({
        id: id,
        name: event.target.value,
      })
    )
  }

  const handleDelete = (event, id) => {
    updateHousehold(deletedHouseHoldMembers(id))
  }

  const handleAdd = () => {
    updateHousehold(
      addeddHouseHoldMembers({
        id: householdItem.members.length + 1,
        name: undefined,
        washingHabits: addWeekDays(),
      })
    )
  }

  const addeddHouseHoldMembers = member => ({
    ...householdItem,
    members: [...householdItem.members, member],
  })

  const updatedHouseHoldMembers = updatedMember => ({
    ...householdItem,
    members: householdItem.members.map(member => {
      if (member.id === updatedMember.id) {
        return { ...member, ...updatedMember }
      }
      return member
    }),
  })

  const deletedHouseHoldMembers = memberId => ({
    ...householdItem,
    members: householdItem.members.filter(member => member.id !== memberId),
  })

  return (
    <Body
      title={
        title ||
        intl.formatMessage({
          id: "members.title",
        })
      }
    >
      <div className="row pb-l">
        <div className="col-12 col-sm-6 offset-sm-3">
          <div className="form-container">
            <p className="f-bold">
              {subtitle || <FormattedMessage id={"members.card.title"} />}
            </p>
            {householdItem.members.map(member => (
              <Input
                key={member.id}
                id={member.id}
                name={member.name}
                placeholder="members.form.field.placeholder"
                value={member.name}
                type="text"
                clearable={householdItem.members.length > min}
                onClear={handleDelete}
                onChange={handleNameChange}
              />
            ))}
            <button
              className={cn("link -primary -pre", {
                "-disabled": householdItem.members.length >= max,
              })}
              onClick={handleAdd}
            >
              <span className="link__icon icon-plus"></span>
              <span className="link__label">Ajouter un membre</span>
            </button>
          </div>
          {displaySave && (
            <div className="text-center mt-xl">
              <button onClick={handleClickSave} className="button -primary">
                <span className="button__label">
                  <FormattedMessage id="save" />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </Body>
  )
}

Member.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  displaySave: PropTypes.bool,
  locale: PropTypes.string,
  intl: intlShape.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
}

Member.defaultProps = {
  displaySave: false,
  min: 1,
  max: 6,
}

export default injectIntl(Member)
