import React, { useEffect } from "react"
import Body from "../Dashboard/Body"
import Card from "./Card"
import { injectIntl, intlShape } from "react-intl"
import FeedBackList from "./FeedBackList"
import { useFeedback } from "../../reducers/feedbackReducer"
import { firebaseAppAuth } from "../../firebase"
import { useUser } from "../../reducers/userReducer"
import { toast } from "react-toastify"

const Feedback = ({ intl }) => {
  const { userItem } = useUser()
  const {
    error,
    feedbackItem,
    feedbackItems,
    fetchFeedback,
    fetchFeedbacks,
  } = useFeedback()

  useEffect(() => {
    if (firebaseAppAuth.currentUser) {
      fetchFeedback(userItem.sensaUser.uuid)
      fetchFeedbacks(userItem.sensaUser.uuid)
    }
    if (error) {
      toast.error(
        intl.formatMessage({
          id: "feedback.fetch.action.error",
        })
      )
    }
  }, [fetchFeedback, fetchFeedbacks, error, intl, userItem.sensaUser])

  return (
    <Body
      title={intl.formatMessage({
        id: "feedback.title",
      })}
    >
      <div className="row">
        <Card
          value={`${feedbackItem.benefit ? feedbackItem.benefit : "- - "} €`}
          label={intl.formatMessage({
            id: "saved",
          })}
          size="large"
        />
      </div>
      <div className="row">
        <Card
          value={`- - g ${intl.formatMessage({ id: "of" })} CO₂`}
          label={intl.formatMessage({ id: "saved" })}
        />
        <Card
          value="- - %"
          label={intl.formatMessage({ id: "feedback.card.two.label" })}
          offset={false}
        />
      </div>

      <div className="row pb-l">
        {feedbackItems && feedbackItems.length > 0 && <FeedBackList feedbacks={feedbackItems} />}
      </div>
    </Body>
  )
}

Feedback.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(Feedback)
