import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { useMediaQuery } from "react-responsive"
import { Devices } from "../../../constants"

const isAsync = paramFunction =>
  paramFunction.constructor.name === "AsyncFunction"

const Previous = ({ StepWizardInstance, onClick }) => {
  const handleClick = async event => {
    let previousStep = false
    if (isAsync(onClick)) {
      previousStep = await onClick()
    } else {
      previousStep = onClick(event)
    }

    // if (!previousStep.hasError) {
    if (previousStep.index === -1) {
      StepWizardInstance.previousStep(event)
    } else {
      StepWizardInstance.goToStep(previousStep.index)
    }
    // }
  }
  const isMobile = useMediaQuery(Devices.MOBILE)
  return (
    <div className={`container ${isMobile ? "pt-xs" : "pt-xl"}`}>
      <div className="row">
        <button className="button -outline" onClick={handleClick}>
          <span className="button__icon icon-arrow-back"></span>
          <span className="button__label">
            <FormattedMessage id="back" />
          </span>
        </button>
      </div>
    </div>
  )
}

Previous.propTypes = {
  StepWizardInstance: PropTypes.shape({
    previousStep: PropTypes.func,
    nextStep: PropTypes.func,
  }),
  onClick: PropTypes.func.isRequired,
}

Previous.defaultProps = {
  onClick: () => ({
    hasError: false,
    index: -1,
  }),
}

export default Previous
