import React from "react"
import PropTypes from "prop-types"
import TimeSliceItem from "./TimeSliceItem"
import { FormattedMessage } from "react-intl"

const PlaningItem = ({ type, availableTimeSlices, timeSlices, onChange }) => {
  const updatedTimeSlices = (value, checked) =>
    checked === true
      ? [...timeSlices, value]
      : timeSlices.filter(
          item =>
            item.start.hour !== value.start.hour &&
            item.end.hour !== value.end.hour
        )

  const handleChange = (timeRangeItem, checked) => {
    onChange({
      type,
      timeSlices: updatedTimeSlices(timeRangeItem, checked),
    })
  }

  return (
    <div className="schedule-list__item">
      <div className="schedule-list__item-title">
        {<FormattedMessage id={type.toLowerCase()} />}
      </div>
      <div className="schedule-list__item-choices">
        {availableTimeSlices.map((timeSlice, index) => (
          <TimeSliceItem
            key={index}
            value={timeSlice}
            checked={timeSlices.some(
              item =>
                item.start.hour === timeSlice.start.hour &&
                item.end.hour === timeSlice.end.hour
            )}
            onChange={handleChange}
          />
        ))}
      </div>
    </div>
  )
}

PlaningItem.propTypes = {
  type: PropTypes.string.isRequired,
  availableTimeSlices: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.shape({
        hour: PropTypes.number.isRequired,
        minute: PropTypes.number.isRequired,
      }),
      end: PropTypes.shape({
        hour: PropTypes.number.isRequired,
        minute: PropTypes.number.isRequired,
      }),
    })
  ),
  timeSlices: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.shape({
        hour: PropTypes.number.isRequired,
        minute: PropTypes.number.isRequired,
      }),
      end: PropTypes.shape({
        hour: PropTypes.number.isRequired,
        minute: PropTypes.number.isRequired,
      }),
    })
  ),
  onChange: PropTypes.func.isRequired,
}

PlaningItem.defaultProps = {
  availableTimeSlices: [
    {
      start: {
        hour: 5,
        minute: 0,
      },
      end: {
        hour: 9,
        minute: 0,
      },
    },
    {
      start: {
        hour: 9,
        minute: 0,
      },
      end: {
        hour: 13,
        minute: 0,
      },
    },
    {
      start: {
        hour: 13,
        minute: 0,
      },
      end: {
        hour: 17,
        minute: 0,
      },
    },
    {
      start: {
        hour: 17,
        minute: 0,
      },
      end: {
        hour: 21,
        minute: 0,
      },
    },
    {
      start: {
        hour: 21,
        minute: 0,
      },
      end: {
        hour: 1,
        minute: 0,
      },
    },
    {
      start: {
        hour: 1,
        minute: 0,
      },
      end: {
        hour: 5,
        minute: 0,
      },
    },
  ],
  timeSlices: [],
}

export default PlaningItem
