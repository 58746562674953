import React, { useState } from "react"
import PropTypes from "prop-types"

import FeedbackItem from "./FeedbackItem"
import FadeIn from "react-fade-in"
import { FormattedMessage } from "react-intl"
import CollapseButton from "../CollapseButton"
const FeedBackList = ({ feedbacks }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = event => {
    setIsOpen(event.target.value)
  }
  return (
    <div className="col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
      <div className="form-container">
        <CollapseButton onClick={handleClick}>
          <FormattedMessage id="feedback.detail.title" />
        </CollapseButton>
        {isOpen && (
          <div className="w-100 mt-m">
            {feedbacks.map((feedback, index) => (
              <FadeIn key={index} delay={index * 200} transitionDuration={500}>
                <FeedbackItem
                  feedback={feedback}
                  separator={index !== feedbacks.length - 1}
                />
              </FadeIn>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

FeedBackList.propTypes = {
  feedbacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
      consumption: PropTypes.number.isRequired,
      unitPrice: PropTypes.number.isRequired,
      sensaUnitPrice: PropTypes.number.isRequired,
      toPaySensaAmount: PropTypes.number.isRequired,
      toPayAmount: PropTypes.number.isRequired,
      benefit: PropTypes.number.isRequired,
    })
  ),
}

FeedBackList.propDefault = {
  feedbacks: [],
}

export default FeedBackList
