import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"

const isAsync = paramFunction =>
  paramFunction.constructor.name === "AsyncFunction"

export const goToNextStep = async (event, onClick, StepWizardInstance) => {
  let nextStep = false
  if (isAsync(onClick)) {
    nextStep = await onClick()
  } else {
    nextStep = onClick(event)
  }

  if (!nextStep.hasError && StepWizardInstance) {
    if (nextStep.index === -1) {
      StepWizardInstance.nextStep(event)
    } else {
      StepWizardInstance.goToStep(nextStep.index)
    }
  }
}
const Next = ({ StepWizardInstance, onClick }) => {
  const handleClick = async event => {
    goToNextStep(event, onClick, StepWizardInstance)
  }

  return (
    <div className="text-center pb-xl">
      <button onClick={handleClick} className="button -primary">
        <span className="button__label">
          <FormattedMessage id="continue" />
        </span>
      </button>
    </div>
  )
}

Next.propTypes = {
  StepWizardInstance: PropTypes.shape({
    previousStep: PropTypes.func,
    nextStep: PropTypes.func,
  }),
  onClick: PropTypes.func.isRequired,
}

Next.defaultProps = {
  onClick: () => ({
    hasError: false,
    index: -1,
  }),
}

export default Next
