import React, { useRef, useReducer, useState, useEffect } from "react"
import Body from "../Dashboard/Body"
import Form from "../Form/Form"
import { Validators } from "../Form"
import { FormattedMessage, injectIntl, intlShape } from "react-intl"
import Feedback from "./Feedback"
import { useUser } from "../../reducers/userReducer"
import emailService from "../../services/email"
import { toast } from "react-toastify"

const initialState = [
  {
    type: "email",
    id: "email",
    value: "",
    name: "email",
    placeholder: "contact.form.field.email.placeholder",
    validators: [Validators.required, Validators.email],
    errorsMessage: [
      { validator: "required", message: "field.required.validation.message" },
      { validator: "email", message: "field.email.validation.message" },
    ],
  },
  {
    type: "text",
    id: "messageTitle",
    value: "",
    name: "messageTitle",
    placeholder: "contact.form.field.message.title.placeholder",
    validators: [Validators.required],
    errorsMessage: [
      { validator: "required", message: "field.required.validation.message" },
    ],
  },
  {
    type: "textarea",
    id: "messageContent",
    value: "",
    name: "messageContent",
    placeholder: "contact.form.field.message.content.placeholder",
    validators: [Validators.required],
    errorsMessage: [
      { validator: "required", message: "field.required.validation.message" },
    ],
  },
]

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return state.map(input => {
        if (input.id === action.payload.inputId) {
          return { ...input, value: action.payload.value }
        }
        return input
      })
    case "SET_ERROR":
      return state.map(input => {
        if (input.id === action.payload.inputId) {
          return { ...input, errorsMessage: action.payload.errorsMessage }
        }
        return input
      })
    default:
      throw new Error()
  }
}
const Contact = ({ intl }) => {
  const formRef = useRef()
  const { userItem } = useUser()
  const [showFeedback, setShowFeedback] = useState(false)
  const [inputs, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    formRef.current.setValue("email", userItem.email)
  }, [userItem])
  const handleChange = (inputId, value) => {
    dispatch({ type: "SET_VALUE", payload: { inputId, value } })
  }

  const handleError = (inputId, errorsMessage) => {
    dispatch({ type: "SET_ERROR", payload: { inputId, errorsMessage } })
  }

  const handleSubmit = event => {
    const formData = formRef.current.submit()
    const email = {
      tittle: intl.formatMessage(
        {
          id: "contact.template.support.email.title",
        },
        {
          lastName: userItem.sensaUser.lastName,
          firstName: userItem.sensaUser.firstName,
        }
      ),
      contentLine1: intl.formatMessage({
        id: "contact.template.support.email.content.line1",
      }),
      contentLine2: intl.formatMessage({
        id: "contact.template.support.email.content.line2",
      }),
      customerFooterLine1: intl.formatMessage({
        id: "contact.template.customer.email.footer.line1",
      }),
      customerFooterLine2: intl.formatMessage({
        id: "contact.template.customer.email.footer.line2",
      }),
      customerContentLine1: intl.formatMessage(
        {
          id: "contact.template.customer.email.content.line1",
        },
        {
          firstName: userItem.sensaUser.firstName,
        }
      ),
      customerContentLine2: intl.formatMessage({
        id: "contact.template.customer.email.content.line2",
      }),
      customerContentLine3: intl.formatMessage({
        id: "contact.template.customer.email.content.line3",
      }),
      emitterDisplayNameLabel: intl.formatMessage({
        id: "contact.template.support.email.emitter.display.name.label",
      }),
      emitterDisplayName: `${userItem.sensaUser.lastName} ${userItem.sensaUser.firstName}`,
      requestLabel: intl.formatMessage({
        id: "contact.template.support.email.request.label",
      }),
      request: formData.messageTitle,
      messageContentLabel: intl.formatMessage({
        id: "contact.template.support.email.message.content.label",
      }),
      messageContent: formData.messageContent,
      emitterEmailLabel: intl.formatMessage({
        id: "contact.template.support.email.emitter.email.label",
      }),
      sensaSignatureLine1: intl.formatMessage({
        id: "contact.template.support.email.sensa.signature.line1",
      }),
      sensaSignatureLine2: intl.formatMessage({
        id: "contact.template.support.email.sensa.signature.line2",
      }),
      sensaSignatureLine3: intl.formatMessage({
        id: "contact.template.support.email.sensa.signature.line3",
      }),
      footerLine1: intl.formatMessage({
        id: "contact.template.customer.email.footer.line1",
      }),
      footerLine2: intl.formatMessage({
        id: "contact.template.customer.email.footer.line2",
      }),
      sensaSignatureEmail: "info@sensa.energy",
      from: "info@sensa.energy",
      to: formData.email,
      customerSubject: intl.formatMessage({
        id: "contact.template.customer.email.title",
      }),
      subject: intl.formatMessage(
        {
          id: "contact.template.support.email.title",
        },
        {
          lastName: userItem.sensaUser.lastName,
          firstName: userItem.sensaUser.firstName,
        }
      ),
    }
    emailService
      .send(email)
      .then(() => {
        setShowFeedback(true)
      })
      .catch(() => {
        toast.error(
          intl.formatMessage({
            id: "contact.email.action.send.error",
          })
        )
      })
  }

  return (
    <>
      {showFeedback ? (
        <Feedback />
      ) : (
        <Body
          title={intl.formatMessage({
            id: "contact.title",
          })}
          subTitle={intl.formatMessage({
            id: "contact.subtitle",
          })}
        >
          <div className="row">
            <div className="col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
              <Form
                ref={formRef}
                id="contact-form"
                inputs={inputs}
                onChange={handleChange}
                onSubmit={handleSubmit}
                onError={handleError}
              />
              <div className="text-center mt-xl">
                <button className="button -primary" onClick={handleSubmit}>
                  <span className="button__label">
                    <FormattedMessage id="contact.form.button.submit.label" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Body>
      )}
    </>
  )
}

Contact.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(Contact)
