import BaseService from "./"

class EmailService extends BaseService {
  send(email) {
    return new Promise((resolve, reject) => {
      this.getToken().then(token => {
        fetch(`${this.url}/user/send/email`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(email),
        })
          .then(response => response.json())
          .then(response =>
            response.error ? reject(response) : resolve(response)
          )
          .catch(error => {
            reject(error)
          })
      })
    })
  }
}

export default new EmailService()
