import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useHousehold, NEW } from "../../reducers/householdReducer"
import WizardSurvey from "../WizardSurvey/WizardSurvey"
import Dashboard from "../Dashboard/Dashboard"
import { useUser } from "../../reducers/userReducer"
import { firebaseAppAuth } from "../../firebase"
import { intlShape, injectIntl } from "react-intl"

const Home = ({ intl, locale }) => {
  const {
    householdItem,
    fetchHousehold,
    updateHousehold,
  } = useHousehold()
  const { userItem } = useUser()

  useEffect(() => {
    if (firebaseAppAuth.currentUser) {
      fetchHousehold(userItem.sensaUser.uuid)
    }
  }, [fetchHousehold, intl, userItem.sensaUser])

  const handleChange = value => {
    updateHousehold(value)
  }

  return (
    <>
      {householdItem.status && (
        <>
          {householdItem.status === NEW ? (
            <WizardSurvey
              locale={locale}
              household={householdItem}
              onChange={handleChange}
            />
          ) : (
            <Dashboard household={householdItem} onChange={handleChange} />
          )}
        </>
      )}
    </>
  )
}

Home.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
}

export default injectIntl(Home)
