import React, { Component } from "react"
import PropTypes from "prop-types"
import Input, { InputPropTypes } from "./Input"

class Form extends Component {
  constructor(props) {
    super(props)
    this.inputsRef = new Map()
  }

  handleChange = (event, inputId) => {
    this.props.onChange(inputId, event.target.value, this.props.id)
  }

  handleError = (event, inputId, errors) => {
    this.props.onError(inputId, errors, this.props.id)
  }

  handleSubmit = event => {
    this.props.onSubmit(event)
    event.preventDefault()
  }

  submit = () => {
    return Array.from(this.inputsRef.values())
      .filter(inputRef => inputRef != null)
      .reduce((obj, inputRef) => {
        inputRef.getWrappedInstance().setPristine(false)
        const errors = inputRef
          .getWrappedInstance()
          .validate(inputRef.props.value)
        this.props.onError(inputRef.props.id, errors, this.props.id)
        obj[inputRef.props.id] = inputRef.props.value
        return obj
      }, {})
  }

  isValid = () => {
    return Array.from(this.inputsRef.values())
      .filter(inputRef => inputRef != null)
      .every(inputRef => {
        return (
          inputRef.getWrappedInstance().validate(inputRef.props.value)
            .length === 0
        )
      })
  }

  setValue = (inputId, value) => {
    const inputRef = this.inputsRef.get(inputId)
    if (inputRef) {
      inputRef.getWrappedInstance().setValue(value)
    }
  }
  render = () => {
    const { inputs, id } = this.props
    return (
      <form id={id} onSubmit={this.handleSubmit}>
        {inputs.map((input, index) => (
          <Input
            key={input.id}
            ref={inputRef => this.inputsRef.set(input.id, inputRef)}
            id={input.id}
            label={input.label}
            placeholder={input.placeholder}
            options={input.options}
            type={input.type}
            name={input.name}
            value={input.value}
            validators={input.validators}
            errorsMessage={input.errorsMessage}
            onChange={this.handleChange}
            onError={this.handleError}
          />
        ))}
      </form>
    )
  }
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape(InputPropTypes)),
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default Form
