import React from "react"
import PropTypes from "prop-types"
import { utcToZonedTime, format } from "date-fns-tz"
import { fr } from "date-fns/esm/locale"
import cn from "classnames"
import { FormattedMessage } from "react-intl"

const FORMAT_ARGS = { timezone: "Europe/Paris", locale: fr }

const FeedbackItem = ({ separator, feedback }) => {
  const formatedDate = timestamp => {
    const zonedDate = utcToZonedTime(new Date(timestamp), FORMAT_ARGS.timeZone)
    return format(zonedDate, "EEEE dd/MM/yyyy", FORMAT_ARGS)
  }

  const formatedHour = timestamp => {
    const zonedDate = utcToZonedTime(new Date(timestamp), FORMAT_ARGS.timeZone)
    return format(zonedDate, "HH'h'mm", FORMAT_ARGS)
  }

  return (
    <div className={cn("w-100 py-m ", { "separator-bottom": separator })}>
      <div className="flex flex-between flex-wrap f-m f-bold c-grey08 pb-xxs">
        <span>{formatedDate(feedback.start)}</span>
        <span>{feedback.benefit}€</span>
      </div>
      <div className="flex flex-between flex-wrap f-s c-grey06">
        <span>
          <FormattedMessage
            id="feedback.detail.list.item.recharging.between"
            values={{
              startDate: formatedHour(feedback.start),
              endDate: formatedHour(feedback.end),
            }}
          />
        </span>
        <span>
          -{" "}- <FormattedMessage id="of" /> CO₂
        </span>
      </div>
    </div>
  )
}

FeedbackItem.propTypes = {
  separator: PropTypes.bool.isRequired,
  feedback: PropTypes.shape({
    id: PropTypes.number.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    consumption: PropTypes.number.isRequired,
    unitPrice: PropTypes.number.isRequired,
    sensaUnitPrice: PropTypes.number.isRequired,
    toPaySensaAmount: PropTypes.number.isRequired,
    toPayAmount: PropTypes.number.isRequired,
    benefit: PropTypes.number.isRequired,
  }).isRequired,
}

export default FeedbackItem
