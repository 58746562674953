import React from "react"
import PropTypes from "prop-types"

const TimeSliceItem = ({ value, checked, onChange }) => {
  const handleChange = event => {
    onChange(value, event.target.checked)
  }

  return (
    <label className="schedule-list__item-choice">
      <div className="schedule-list__item-choices__check">
        <input
          onChange={handleChange}
          className="schedule-list__item-choices__input"
          checked={checked}
          type="checkbox"
        />
        <span className="schedule-list__item-choices__checker">
          {value.start.hour}-{value.end.hour}h
        </span>
      </div>
    </label>
  )
}

TimeSliceItem.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.shape({
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
    }),
    end: PropTypes.shape({
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
    }),
  }),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

TimeSliceItem.defaultProps = {
  checked: false,
}

export default TimeSliceItem
