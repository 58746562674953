import React from "react"

import Body from "../Dashboard/Body"
import Link from "../LocalizedLink"
import { FormattedMessage, intlShape, injectIntl } from "react-intl"
const Feedback = ({ intl }) => {
  return (
    <Body
      title={intl.formatMessage({
        id: "contact.feedback.title",
      })}
      subTitle={intl.formatMessage({
        id: "contact.feedback.subtitle",
      })}
    >
      <div className="text-center pt-s">
        <Link to="/">
          <button type="submit" className="button -primary">
            <span className="button__label">
              <FormattedMessage id="go.to.home" />
            </span>
          </button>
        </Link>
      </div>
    </Body>
  )
}

Feedback.propTypes = {
  intl: intlShape.isRequired,
}

export default injectIntl(Feedback)
