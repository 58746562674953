import React from "react"
import PropTypes from "prop-types"
import Body from "../Dashboard/Body"
import { injectIntl, intlShape } from "react-intl"
import Card from "../Card"
function SizeOfBoiler({ intl, value, onChange }) {
  return (
    <Body
      title={intl.formatMessage({
        id: "size.of.boiler.title",
      })}
    >
      <div className="row pb-l">
        <div className="col-6 col-md-4 col-lg-2 offset-0 offset-md-0 offset-lg-3 my-m">
          <Card
            value="SMALL"
            label={intl.formatMessage({
              id: "size.of.boiler.card.one.title",
            })}
            onClick={onChange}
            isActive={value === "SMALL"}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-2 offset-0 offset-md-0 offset-lg-0 my-m">
          <Card
            value="MEDIUM"
            label={intl.formatMessage({
              id: "size.of.boiler.card.two.title",
            })}
            onClick={onChange}
            isActive={value === "MEDIUM"}
          />
        </div>
        <div className="col-6 col-md-4 col-lg-2 offset-0 offset-md-0 offset-lg-0 my-m">
          <Card
            value="BIG"
            label={intl.formatMessage({
              id: "size.of.boiler.card.three.title",
            })}
            onClick={onChange}
            isActive={value === "BIG"}
          />
        </div>
      </div>
    </Body>
  )
}

SizeOfBoiler.propTypes = {
  intl: intlShape.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default injectIntl(SizeOfBoiler)
