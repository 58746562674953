import React, { useEffect } from "react"
import { injectIntl, intlShape } from "react-intl"
import { getLocalizedPath } from "../i18n"
import { Router } from "@reach/router"
import { compose } from "recompose"

import withLayout from "../layout"
import withPageContext from "../pageContext"

import SEO from "../components/SEO"
import PrivateRoute from "../components/PrivateRoute"
import Login from "../components/Login"
import Loader from "../components/Loader"
import { useUser } from "../reducers/userReducer"
import { useAuthChange } from "../firebase"
import Dashboard from "../components/Dashboard"
import Member from "../components/Member"
import Contact from "../components/Contact"
import Home from "../components/Home"
import { useHousehold } from "../reducers/householdReducer"
import { toast } from 'react-toastify'

const App = ({ intl, pageContext }) => {
  const {
    loading: userLoading,
    error: userError,
    addUser,
    removeUser,
  } = useUser()
  const { loading: householdLoading, error: householdError } = useHousehold()
  const loading = userLoading || householdLoading
  useAuthChange(addUser, removeUser)

  useEffect(() => {
    if (householdError) {
      toast.error(
        intl.formatMessage({
          id: "household.fetch.action.error",
        })
      )
    } else if (userError) {
      toast.error(
        intl.formatMessage({
          id: "user.fetch.action.error",
        })
      )
    }
  })
  return (
    <React.Fragment>
      <SEO
        title={intl.formatMessage({ id: "home.title" })}
        lang={pageContext.locale}
        description={intl.formatMessage({ id: "home.description" })}
      />
      {loading && <Loader loading={loading} />}
      <Router>
        <PrivateRoute
          path={getLocalizedPath("/app/home/", pageContext.locale)}
          locale={pageContext.locale}
          component={Home}
        />
        <PrivateRoute
          path={getLocalizedPath("/app/dashboard/", pageContext.locale)}
          locale={pageContext.locale}
          component={Dashboard}
        />
        <PrivateRoute
          path={getLocalizedPath("/app/members/", pageContext.locale)}
          locale={pageContext.locale}
          displaySave
          component={Member}
        />
        <PrivateRoute
          path={getLocalizedPath("/app/contact/", pageContext.locale)}
          locale={pageContext.locale}
          component={Contact}
        />
        <Login
          path={getLocalizedPath("/app/login/", pageContext.locale)}
          locale={pageContext.locale}
        />
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  intl: intlShape.isRequired,
}

export default compose(withPageContext, withLayout, injectIntl)(App)
