import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Body from "../Dashboard/Body"
import Planing from "../Planning"
import { injectIntl, intlShape, FormattedMessage } from "react-intl"
import Select from "../Form/Select"
import Link from "../LocalizedLink"
import { useCallback } from "react"
import { useHousehold } from "../../reducers/householdReducer"
import { toast } from "react-toastify"
const MyPlaning = ({
  intl,
  members,
  showModifyMember,
  displaySave,
  onChange,
}) => {
  const [currentMember, setCurrentMember] = useState(members[0])
  const { householdItem, saveHousehold } = useHousehold()

  const setMemberById = useCallback(
    id => {
      const member = members.find(member => member.id === id)
      setCurrentMember(member)
    },
    [members]
  )

  useEffect(() => {
    setMemberById(currentMember.id)
  }, [setMemberById, currentMember.id])

  const updatedWashingHabits = value =>
    currentMember.washingHabits.map(washingHabit => {
      if (
        intl.formatMessage({ id: washingHabit.day.toLowerCase() }) ===
        value.target
      ) {
        const { target, ...rest } = value
        return { ...washingHabit, ...rest }
      }
      return washingHabit
    })

  const handleMembersChange = item => {
    setMemberById(item.value)
  }

  const handleChange = planing => {
    onChange({ ...currentMember, washingHabits: updatedWashingHabits(planing) })
  }

  const handleSave = () => {
    saveHousehold(householdItem)
      .then(() => {
        toast.success(
          intl.formatMessage({ id: "my.planing.save.action.success" })
        )
      })
      .catch(() => {
        toast.error(intl.formatMessage({ id: "my.planing.save.action.error" }))
      })
  }

  return (
    <Body title={intl.formatMessage({ id: "my.planing.title" })}>
      <div className="row pb-l">
        <div className="col-12 col-md-3 offset-md-2 flex flex-middle">
          <span className="mr-s">
            <FormattedMessage id="member" />:
          </span>
          <Select
            id="members"
            name="members"
            value={currentMember.name}
            options={members.map(member => ({
              value: member.id,
              label: member.name,
            }))}
            onChange={handleMembersChange}
            styles="dropdown-content"
          />
        </div>
        {showModifyMember && (
          <div className="col-12 col-md-3 offset-md-2 pt-s">
            <Link className="link -primary" to="/app/members">
              <span className="link__label text-right">
                <FormattedMessage id="my.planing.link.edit.members" />
              </span>
            </Link>
          </div>
        )}
      </div>

      {currentMember.washingHabits.map((washingHabit, index) => (
        <Planing
          key={index}
          target={intl.formatMessage({ id: washingHabit.day.toLowerCase() })}
          devices={washingHabit.devices.map(device => ({
            type: device.type,
            timeSlices: device.timeSlices,
          }))}
          onChange={handleChange}
        />
      ))}
      {displaySave && (
        <div className="text-center mt-xl">
          <button onClick={handleSave} className="button -primary">
            <span className="button__label">
              <FormattedMessage id="save" />
            </span>
          </button>
        </div>
      )}
    </Body>
  )
}

MyPlaning.propTypes = {
  intl: intlShape.isRequired,
  showModifyMember: PropTypes.bool,
  displaySave: PropTypes.bool,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
      washingHabits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          day: PropTypes.string.isRequired,
          devices: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string.isRequired,
              timeSlices: PropTypes.arrayOf(
                PropTypes.shape({
                  start: PropTypes.shape({
                    hour: PropTypes.number.isRequired,
                    minute: PropTypes.number.isRequired,
                  }),
                  end: PropTypes.shape({
                    hour: PropTypes.number.isRequired,
                    minute: PropTypes.number.isRequired,
                  }),
                })
              ),
            })
          ).isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

MyPlaning.defaultProps = {
  showModifyMember: false,
  displaySave: false,
}

export default injectIntl(MyPlaning)
